import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useConfigStore } from "@/stores/config";
import { useLoadingBarStore } from "@/stores/loadingBar";
import leadDistributionRoutes from "@/router/leadDistribution";
import administrationRoutes from "@/router/administration";
import financeRoutes from "@/router/finance";

const masterRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/default-layout/DefaultLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      ... administrationRoutes,
      ... leadDistributionRoutes,
      ... financeRoutes,
      {
        path: "/settings",
        name: "settings",
        children: [
          {
            path: "/settings/global-postback",
            name: "globalPostback",
            component: () => import("@/views/settings/GlobalPostback.vue"),
            meta: {
              pageTitle: "Global Postback",
              breadcrumbs: ["Global Postback"],
            }
          }
        ]
      }, 
      {
        path: "/user/manage-profile",
        name: "userProfile",
        component: () => import("@/views/profile/Profile.vue"),
        meta: {
          pageTitle: "Manage Profile",
          breadcrumbs: ["Profile"],
        }
      }
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
          middleware: "guest"
        },
      }
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: masterRoutes,
  scrollBehavior(to) {
    // If the route has a hash, scroll to the section with the specified ID; otherwise, scroll to the top of the page.
    if (to.hash) {
      return {
        el: to.hash,
        top: 80,
        behavior: "smooth",
      };
    } else {
      return {
        top: 0,
        left: 0,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach(async (to, from, next) => {

  const authStore = useAuthStore();
  const configStore = useConfigStore();
  const storeLoadingBar = useLoadingBarStore();

  await authStore.setUserMenus();

  storeLoadingBar.startLoading();
  
  const userMenus: string[] = authStore.userMenus.length ? authStore.userMenus : [];

  document.title = `${to.meta.pageTitle} - ${import.meta.env.VITE_APP_NAME}`;

  configStore.resetLayoutConfig();

  if (to.meta.middleware === "auth") {
    
    const { isAuthenticated, user } = authStore;
  
    if (isAuthenticated) {
      (user.role === "super_admin" || userMenus.includes(to.name as string) || userMenus.includes(to.meta.parentName as string))
        ? next()
        : next({ name: "404" });
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }  

});

router.afterEach(route => {
  const storeLoadingBar = useLoadingBarStore();
  storeLoadingBar.stopLoading();
});

export default router;
