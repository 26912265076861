<template>
    <div class="loading-bar" v-if="loadingBarStore.loadingPercent">
        <el-progress 
            :percentage="loadingBarStore.loadingPercent < 101? loadingBarStore.loadingPercent : 100" 
            :show-text="false"
            :status="loadingBarStore.progressColor"
        />
    </div>
</template>

<script lang="ts" setup>
import { useLoadingBarStore } from '@/stores/loadingBar';

const loadingBarStore = useLoadingBarStore();

</script>

<style>
.loading-bar .el-progress--line {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
}

.loading-bar .el-progress-bar__outer {
    background: none !important;
    border-radius: 0 !important;
}

.loading-bar .el-progress-bar__inner {
    border-radius: 0 !important;
    height: 3px !important;
}
</style>