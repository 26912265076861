import { defineStore } from "pinia";
import { ref } from "vue";

export const useLoadingBarStore = defineStore("loadingBar", () => {
    const loadingPercent = ref(0);
    const progressColor = ref("exception");

    const startLoading = () => {
        
        if(loadingPercent.value > 0) return

        const interval = setInterval(() => {
            loadingPercent.value += 2;
            if (loadingPercent.value >= 95) {
                clearInterval(interval)
            }
        }, 100);
    }

    const stopLoading = () => {
        loadingPercent.value = 100
        destroyLoader();
    }

    const failedLoading = () => {
        loadingPercent.value = 100
        destroyLoader();
    }

    const destroyLoader = () => {
        setTimeout(() => {
            loadingPercent.value = 0
        }, 500)
    }

    return {
        loadingPercent,
        startLoading,
        stopLoading,
        progressColor,
        failedLoading
    }
});