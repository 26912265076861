import type { RouteRecordRaw } from "vue-router";

const leadDistributionRoutes: Array<RouteRecordRaw> = [
    {
        path: "/lead-distribution",
        name: "leadDistribution",
        children: [
          {
            path: "reporting",
            name: "reporting",
            component: () => import("@/views/lead-distribution/Reporting.vue"),
            meta: {
              pageTitle: "Reporting",
              breadcrumbs: ["Lead Distribution", "Reporting"],
            }
          },
          {
            path: "reporting/:reportId",
            name: "singleReporting",
            component: () => import("@/views/lead-distribution/Reporting.vue"),
            meta: {
              pageTitle: "Reporting",
              breadcrumbs: ["Lead Distribution", "Reporting"],
            }
          }
        ]
    },
    {
      path: "leads",
      name: "leads",
      component: () => import("@/views/lead-distribution/Lead.vue"),
      meta: {
        pageTitle: "Leads",
        breadcrumbs: ["Lead Distribution", "Lead"],
      }
    },
    {
      path: "platform-list-ping-log",
      name: "platform-list-ping-log",
      component: () => import("@/views/lead-distribution/PingLog.vue"),
      meta: {
        pageTitle: "Ping Log",
        breadcrumbs: ["Lead Distribution", "Ping Log"],
      }
    },
    {
      path: "platform-list-postback-log",
      name: "platform-list-postback-log",
      component: () => import("@/views/postback-logs/PostBackLog.vue"),
      meta: {
        pageTitle: "Postback Log",
        breadcrumbs: ["Lead Distribution", "Postback Log"],
      }
    },
    {
      path: "caps-overview",
      name: "caps-overview",
      component: () => import("@/views/lead-distribution/CapsOverview.vue"),
      meta: {
        pageTitle: "Caps Overview",
        breadcrumbs: ["Lead Distribution", "Caps Overview"],
      }
    },
    {
      name: "platform",
      path: "/platform",
      children: [
        {
          path: "list",
          name: "platformList",
          component: () => import("@/views/lead-distribution/platform/PlatformList.vue"),
          meta: {
            pageTitle: "Lists",
            breadcrumbs: ["Lead Distribution", "Lists"]
          }
        },
        {
          path: "platform-list/:platformId",
          name: "platformDetails",
          component: () => import("@/components/page-layouts/Platform.vue"),
          meta: {
            breadcrumbs: ["Lead Distribution", "List"],
            pageTitle: "Platform Details"
          },
          children: [
            {
              path: "leads",
              name: "platform-leads",
              component: () => import("@/views/lead-distribution/Lead.vue"),
              meta: {
                pageTitle: "Leads",
                breadcrumbs: ["Lead Distribution", "List"],
                parentName: "platformDetails"
              },
            },
            {
              path: "integrations",
              name: "platform-integrations",
              component: () => import("@/views/lead-distribution/platform/Integration.vue"),
              meta: { 
                pageTitle: "Integrations",
                breadcrumbs: ["Lead Distribution", "List"],
                parentName: "platformDetails"
              },
            },
            {
              path: "posting-specs",
              name: "platform-posting-specs",
              component: () => import("@/views/lead-distribution/platform/PostingSpecs.vue"),
              meta: { 
                pageTitle: "Posting Specs",
                breadcrumbs: ["Lead Distribution", "List"],
                parentName: "platformDetails"
              },
            },
            {
              path: "headers",
              name: "platform-headers",
              component: () => import("@/views/lead-distribution/platform/Headers.vue"),
              meta: { 
                pageTitle: "Headers",
                parentName: "platformDetails" 
              },
            },
            {
              path: "criteria",
              name: "platform-criteria",
              component: () => import("@/views/lead-distribution/platform/Criteria.vue"),
              meta: { 
                pageTitle: "Criteria",
                parentName: "platformDetails"
              },
            },
            {
              path: "settings",
              name: "platform-settings",
              component: () => import("@/views/lead-distribution/platform/Settings.vue"),
              meta: {  
                pageTitle: "Settings",
                parentName: "platformDetails"
              },
            },
          ],
        },
      ]
    },
    {
      path: "/affiliates",
      name: "affiliates",
      component: () => import("@/views/affiliates/AffiliateList.vue"),
      meta: {
        pageTitle: "Affiliates",
        breadcrumbs: ["Affiliates"],
      }
    }, 
    {
      path: "/conference",
      name: "conference",
      component: () => import("@/views/conference/ConferenceList.vue"),
      meta: {
        pageTitle: "Conferences",
        breadcrumbs: ["Conferences"],
      }
    }, 
    {
      path: "/newest-campaign",
      name: "newestCampaign",
      component: () => import("@/views/newest-campaign/NewestCampaignList.vue"),
      meta: {
        pageTitle: "Campaign News",
        breadcrumbs: ["Campaign News"],
      }
    }, 
    {
      path: "/general-information",
      name: "generalInformation",
      component: () => import("@/views/general-information/GeneralInformationList.vue"),
      meta: {
        pageTitle: "General Information",
        breadcrumbs: ["General Information"],
      }
    }
];

export default leadDistributionRoutes;