import type { RouteRecordRaw } from "vue-router";

const administrationRoutes: Array<RouteRecordRaw> = [
  {
    path: "/administration",
    name: "administration",
    children: [
      {
        path: "manage-user",
        component: () => import("@/views/administration/User.vue"),
        meta: {
          pageTitle: "Manage Admin",
          breadcrumbs: ["Administration", "Manage Admin"],
        }
      },
      {
        path: "manage-role",
        component: () => import("@/views/administration/Role.vue"),
        meta: {
          pageTitle: "Manage Role",
          breadcrumbs: ["Administration", "Manage Role"],
        }
      }
    ]
  }
];

export default administrationRoutes;