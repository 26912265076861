import type { User } from "@/core/types/models";

const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * Stores the authenticated user in local storage.
 *
 * @param {User} user
 */
export const setAuthenticatedUser = (user: User) => {
  window.localStorage.setItem("user", JSON.stringify(user));
};

/**
 * Retrieves the authenticated user from the local storage.
 *
 * @return {User | null}
 */
export const getAuthUser = (): User | null => {
  const item = window.localStorage.getItem("user");
  return item ? JSON.parse(item) : null;
};

/**
 * Removes the authenticated user from the local storage.
 *
 * @return {void}
 */
export const removeAuthenticatedUser = (): void => {
  window.localStorage.removeItem("user");
};

/**
 * Stores a value in local storage.
 *
 * @param {string} key The key to store the value under.
 * @param {any} value The value to store.
 */
export const setLocalStorageItem = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Retrieves a value from local storage.
 *
 * @param {string} key The key of the value to retrieve.
 * @return {any} The value associated with the given key, or `null` if not found.
 */
export const getLocalStorageValue = (key: string): any => {
  const item = window.localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
}

/**
 * Removes a value from local storage.
 *
 * @param {string} key The key of the value to remove.
 */
export const removeItemFromLocalStorage = (key: string): void => {
  window.localStorage.removeItem(key);
}

export default { 
  getToken,
  saveToken,
  destroyToken,
  setAuthenticatedUser, 
  getAuthUser,
  removeAuthenticatedUser,
  setLocalStorageItem,
  getLocalStorageValue,
  removeItemFromLocalStorage
};
