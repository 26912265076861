import type { RouteRecordRaw } from "vue-router";

const financeRoutes: Array<RouteRecordRaw> = [
    {
        path: "/finance",
        name: "finance",
        children: [
          {
            path: "invoices",
            name: "invoices",
            component: () => import("@/views/invoice/InvoiceList.vue"),
            meta: {
              pageTitle: "Invoices",
              breadcrumbs: ["Finance", "Invoices"],
            },
          },
          {
            path: "invoice/:tag",
            name: "invoice",
            component: () => import("@/views/invoice/InvoiceDetails.vue"),
            meta: {
              pageTitle: "Invoices",
              breadcrumbs: ["Finance", "Invoices"],
            },
          },
        ],
    }
];

export default financeRoutes;