import { ref } from "vue";
import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import type { User } from "@/core/types/models";


declare global {
  interface Window {
      userAccess: any
  }
}

export const useAuthStore = defineStore("auth", () => {

  const user = ref<User>(JwtService.getAuthUser() as User);
  const isAuthenticated = ref(!!JwtService.getToken());
  const userMenus = ref<string[]>([]);
  const userReportMenus = ref<any[]>([]);
  const userAccess = ref<any>(null);

  function setAuth(authUser: User) {
    isAuthenticated.value = true;
    user.value = authUser;
    JwtService.setAuthenticatedUser(authUser);
    JwtService.saveToken(user.value.api_token);
  }

  function purgeAuth() {
    isAuthenticated.value = false;
    user.value = {} as User;
    JwtService.removeAuthenticatedUser();
    JwtService.destroyToken();
  }

  function logout() {
    purgeAuth();
  }

  function verifyAuth() {

    if (! JwtService.getToken()) return purgeAuth();

    ApiService.setHeader();

    ApiService.post("auth/verify-token", { api_token: JwtService.getToken() })
      .then(({ data }) => setAuth(data.json_data))
      .catch(({ response }) => {
        purgeAuth();
      });
  }

  async function setUserMenus(){
    ApiService.setHeader();
    await ApiService.get("/permission/get-permission")
      .then(({ data }) => {
        const permissionData = data.json_data;
        userMenus.value = permissionData.permissions
        userReportMenus.value = permissionData.saved_reports;
        window.userAccess = permissionData;
        userAccess.value = permissionData;
      })
      .catch(({ response }) => {
        if(response.status === 401){
          purgeAuth();
        }
      });
  }

  return {
    user,
    userMenus,
    userReportMenus,
    isAuthenticated,
    userAccess,
    setAuth,
    logout,
    verifyAuth,
    purgeAuth,
    setUserMenus,
  };
  
});
